import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../contexts/LanguageContext";
import bannerImage from "../assets/logo1.png";
import special from "../data/special.json";
import deal1 from "../assets/deal1.svg";
import deal2 from "../assets/deal2.svg";
import deal3 from "../assets/deal3.svg";
import deal4 from "../assets/deal4.svg";
import deal5 from "../assets/deal5.svg";
import deal6 from "../assets/deal6.svg";
import deal7 from "../assets/deal7.svg";
import deal8 from "../assets/deal8.svg";
import "./Banner.css";

const images = {
  "kirmizi-indirimler": "https://static.istegelsin.com/v3-img/v3-kampanya-icon-nw.svg",
  "yeni-urunler": deal1,
  "organik": deal2,
  "ev-ekonomisi": deal3,
  "cok-satanlar": deal4,
  "biz-tasyalim": deal5,
  "glutensiz": deal6,
  "vegan": deal7,
  "bitiriyoruz": deal8
};

function Banner() {
  const { texts } = useContext(LanguageContext);
  const navigate = useNavigate();

  const handleBannerClick = () => {
    navigate("/redpage");
  };

  return (
    <section className="banner">
      <div className="banner-content">
        <img src={bannerImage} alt="Banner" className="banner-image" onClick={handleBannerClick} style={{ cursor: 'pointer' }} />
        <div className="special-categories">
          {special.map((item, index) => (
            <div key={item.id} className="special-category">
              <img src={images[item.id]} alt={texts.special[index]} />
              <p>{texts.special[index]}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Banner;
