import React, { createContext, useState } from "react";
import texts from "../data/texts";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");

  const selectLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, texts: texts[language], setLanguage: selectLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
