import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import categories from "../data/categories.json";
import "./Categories.css";

function Categories() {
  const { texts } = useContext(LanguageContext);

  return (
    <section className="categories">
      {categories.map((category, index) => (
        <div key={category.id} className="category">
          <img
            src={category.image}
            alt={texts.categories[index] || "Category"}
          />
          <p>{texts.categories[index] || "Category"}</p>
        </div>
      ))}
    </section>
  );
}

export default Categories;
