export const texts = {
  tr: {
    header: {
      menu: "Menü",
      changeLanguage: "Dili Değiştir",
      searchPlaceholder: "Binlerce çeşit içinde ara",
      login: "Giriş Yap veya Üye OL",
      campaigns: "Kampanyalar",
      cart: "Sepet",
    },
    delivery: {
      title: "Siparişiniz Nereye Gelsin?",
      description:
        "Ürün çeşitliliği teslimat bölgenize göre değişiklik gösterebilmektedir, lütfen bölgenizi seçin.",
      button: "Teslimat Bölgesi Seç",
    },
    footer: {
      logoAlt: "İsteGelsin",
      social: {
        instagram: "Instagram",
        facebook: "Facebook",
        twitter: "Twitter",
        youtube: "YouTube",
      },
      corporate: "Kurumsal",
      corporateLinks: [
        { text: "Bilgi Toplumu Hizmetleri", href: "/bilgitoplumuhizmetleri" },
        { text: "Hakkımızda", href: "/hakkimizda" },
        { text: "Yardım", href: "/yardim" },
        { text: "İletişim", href: "/iletisim" },
        {
          text: "KVKK Hakkında Aydınlatma Metni",
          href: "https://static.istegelsin.com/privacy.html",
        },
        {
          text: "Üyelik ve Kullanım Koşulları",
          href: "https://static.istegelsin.com/terms.html",
        },
        {
          text: "Çerez Aydınlatma Metni",
          href: "https://static.istegelsin.com/cookie_privacy.html",
        },
      ],
      conceptCategories: "Konsept Kategoriler",
      conceptCategoryLinks: [
        { text: "Her Üründe İndirim!", href: "/her-urunde-indirim" },
        { text: "Kırmızı İndirimler", href: "/grup/kirmizi-indirimler" },
        { text: "Yeni Ürünler", href: "/grup/yeniurunler0804" },
        { text: "Organik", href: "/grup/organik0710" },
        { text: "Ev Ekonomisi", href: "/grup/pl121021" },
        { text: "Çok Satanlar", href: "/grup/coksatanlar" },
        { text: "Biz Taşıyalım", href: "/grup/biztasyalim" },
        { text: "Glutensiz", href: "/grup/glutensiz0710" },
        { text: "Vegan", href: "/grup/vegan0710" },
        { text: "Bitiriyoruz", href: "/grup/bitiriyoruz" },
      ],
      categories: "Kategoriler",
      categoryLinks: [
        { text: "Meyve, Sebze", href: "/kategori/meyve-sebze_A" },
        { text: "Et, Tavuk, Balık", href: "/kategori/et-tavuk-balik_B" },
        { text: "Süt Ürünleri", href: "/kategori/sut-urunleri_D" },
        { text: "Kahvaltılık", href: "/kategori/kahvaltilik_F" },
        { text: "Temel Gıda", href: "/kategori/temel-gida_G" },
        { text: "Atıştırmalık", href: "/kategori/atistirmalik_J" },
        { text: "Su, İçecek", href: "/kategori/su-icecek_L" },
        { text: "Donuk, Hazır Gıda", href: "/kategori/donuk-hazir-gida_6" },
        { text: "Fırın, Pastane", href: "/kategori/firin-pastane_C" },
        {
          text: "Pasta ve Malzemeleri",
          href: "/kategori/pasta-ve-malzemeleri_K",
        },
        { text: "Tatlı, Dondurma", href: "/kategori/tatli-dondurma_V" },
        { text: "Çay, Kahve", href: "/kategori/cay-kahve_8" },
        { text: "Temizlik, Deterjan", href: "/kategori/temizlik-deterjan_N" },
        { text: "Kişisel Bakım", href: "/kategori/kisisel-bakim_P" },
        { text: "Evcil Dostlara", href: "/kategori/evcil-dostlara_U" },
        { text: "Bebek", href: "/kategori/bebek_R" },
        { text: "Ev Yaşam", href: "/kategori/ev-yasam_O" },
        {
          text: "Vitamin, Dermokozmetik",
          href: "/kategori/vitamin-dermokozmetik_2",
        },
        {
          text: "Teknoloji, Dijital Ürünler",
          href: "/kategori/teknoloji-dijital-urunler_Y",
        },
        { text: "Kitap, Dergi", href: "/kategori/kitap-dergi_S" },
      ],
      rights: "© 2024 Tüm Hakları Saklıdır",
    },
    features: [
      {
        title: "Binlerce ürün çeşidi",
        description:
          "Market alışverişine ve çok daha fazlasına kolayca ulaşabilirsin!",
      },
      {
        title: "Soğuk zincir kırılmadan teslimat",
        description:
          "Ürünlerin soğuk hava depolarında muhafaza edilir, soğutmalı araçlarla kapına gelir",
      },
      {
        title: "Taptaze gelir",
        description:
          "Markete gitmene gerek kalmaz, tüm ürünler taptaze kapına gelir.",
      },
    ],
    categories: [
      "Meyve, Sebze",
      "Et, Tavuk, Balık",
      "Süt Ürünleri",
      "Kahvaltılık",
      "Temel Gıda",
      "Atıştırmalık",
      "Su, İçecek",
      "Donuk, Hazır Gıda",
      "Fırın, Pastane",
      "Pasta ve Malzemeleri",
      "Tatlı, Dondurma",
      "Çay, Kahve",
      "Temizlik, Deterjan",
      "Kişisel Bakım",
      "Evcil Dostlara",
      "Bebek",
      "Ev Yaşam",
      "Vitamin, Dermokozmetik",
      "Teknoloji, Dijital Ürünler",
      "Kitap, Dergi",
    ],
    special: [
      "Kırmızı İndirimler",
      "Yeni Ürünler",
      "Organik",
      "Ev Ekonomisi",
      "Çok Satanlar",
      "Biz Taşıyalım",
      "Glutensiz",
      "Vegan",
      "Bitiriyoruz",
    ],
    chance: {
      title: "Fırsatlar Gelsin",
      points: "Puan",
      button: "Sepete Ekle",
      viewAll: "Tüm Ürünleri Gör",
    },
    about: {
      title: "Hakkımızda",
      description1: "Shopping Call; Tamamen online hizmet veren ilk süpermarket olarak tasarlanmıştır. Market alışverişinin zorluklarını ortadan kaldıracak mobil çözümler sunmak için teknolojiler geliştiriyor ve modern hayatına yeni nesil çözümler sunuyoruz. Dakikalar içinde market ürünlerini kullanıcılarımızla buluşturmayı hedefliyoruz. Zaman içerisinde orijinal iş modelimizi genişleterek ve kullanıcılarımızın çeşitli ihtiyaçlarını karşılamak için onlara farklı hizmetler sunmayı planlıyoruz.",
      description2: "Soğuk hava depolarında muhafaza edilen taptaze sebze ve meyveler, en sevdiğin markalara ait ürünler, fırından çıkmış tazecik unlu mamullere kadar her şey kapına kadar gelsin istiyorsan; platformumuz üzerinden sipariş verebilirsiniz.",
      founders: "Kurucularımız",
      serdar: {
        name: "Serdar ATASAYAR",
        bio: "Anadolu Üniversitesi Halkla ilişkiler Mezunu. 20 yılı aşkın süredir Gıda Sektöründe çeşitli firmalarda üst düzey yönetici olarak çalışmıştır. Yeni girişim iş fikri projesiyle çalışmalarını sürdürmektedir. Son dönemde Shopping Call uygulama üzerinden süpermarket sipariş platformunun kurucusudur.",
        contact: "İletişim",
        address: "Adres",
      },
      ali: {
        name: "Ali AŞILI",
        bio: "Anadolu Üniversitesi Çalışma Ekonomisi Bölümü Mezunudur. Yıllarca çeşitli firmalarda ekonomi yöneticiliği yapmıştır. Son dönemde Shopping Call uygulama üzerinden süpermarket sipariş platformunun yeni girişim ortağıdır.",
        contact: "İletişim",
        address: "Adres",
      },
      burak: {
        name: "Burak MADEN",
        bio: "Atatürk Üniversitesi Bilgisayar Programcılığı Mezunudur. ShoppingCall uygulama üzerinden süpermarket sipariş platformunun yeni girişim ortağıdır.",
        contact: "İletişim",
        address: "Adres",
      }
    },
    contact: {
      contactInfo: "İletişim Bilgilerimiz",
      phone: "Telefon Numarası",
      serdar: "Serdar ATASAYAR - Kurucu",
      ali: "Ali AŞILI - Ortak",
      burak: "Burak MADEN - Ortak",
      email: "E-Posta Adresi",
      headquarters: "ShoppingCall Genel Merkez",
      corporateContact: "Kurumsal İletişim Formu",
      formDescription: "Kurumsal işbirlik talepleriniz ve tüm sorularınız için aşağıdaki formu doldurarak bize ulaşabilirsiniz. Eğer tedarikçi olmak istiyorsanız veya fatura ödeme süreçleri ile ilgili satın alma departmanımıza ulaşmanız gerekiyorsa öncelikle bu formu doldurmalısınız.",
      companyName: "Firma Adı",
      fullName: "Ad Soyad",
      phoneNumber: "Telefon Numarası",
      emailAddress: "E-Posta Adresi",
      productGroup: "Satılmak İstenen Ürün Grubu",
      description: "Açıklama",
      submit: "Gönder",
    },
  },
  en: {
    header: {
      menu: "Menu",
      changeLanguage: "Change Language",
      searchPlaceholder: "Search among thousands of products",
      login: "Login or Sign Up",
      campaigns: "Campaigns",
      cart: "Cart",
    },
    delivery: {
      title: "Where should we deliver your order?",
      description:
        "Product variety may vary by delivery area, please select your area.",
      button: "Select Delivery Area",
    },
    footer: {
      logoAlt: "İsteGelsin",
      social: {
        instagram: "Instagram",
        facebook: "Facebook",
        twitter: "Twitter",
        youtube: "YouTube",
      },
      corporate: "Corporate",
      corporateLinks: [
        {
          text: "Information Society Services",
          href: "/bilgitoplumuhizmetleri",
        },
        { text: "About Us", href: "/hakkimizda" },
        { text: "Help", href: "/yardim" },
        { text: "Contact", href: "/iletisim" },
        {
          text: "KVKK Clarification Text",
          href: "https://static.istegelsin.com/privacy.html",
        },
        {
          text: "Membership and Terms of Use",
          href: "https://static.istegelsin.com/terms.html",
        },
        {
          text: "Cookie Clarification Text",
          href: "https://static.istegelsin.com/cookie_privacy.html",
        },
      ],
      conceptCategories: "Concept Categories",
      conceptCategoryLinks: [
        { text: "Discount on Every Product!", href: "/her-urunde-indirim" },
        { text: "Red Discounts", href: "/grup/kirmizi-indirimler" },
        { text: "New Products", href: "/grup/yeniurunler0804" },
        { text: "Organic", href: "/grup/organik0710" },
        { text: "Household Economy", href: "/grup/pl121021" },
        { text: "Best Sellers", href: "/grup/coksatanlar" },
        { text: "We Carry", href: "/grup/biztasyalim" },
        { text: "Gluten-Free", href: "/grup/glutensiz0710" },
        { text: "Vegan", href: "/grup/vegan0710" },
        { text: "Ending Soon", href: "/grup/bitiriyoruz" },
      ],
      categories: "Categories",
      categoryLinks: [
        { text: "Fruits, Vegetables", href: "/kategori/meyve-sebze_A" },
        { text: "Meat, Chicken, Fish", href: "/kategori/et-tavuk-balik_B" },
        { text: "Dairy Products", href: "/kategori/sut-urunleri_D" },
        { text: "Breakfast", href: "/kategori/kahvaltilik_F" },
        { text: "Staple Foods", href: "/kategori/temel-gida_G" },
        { text: "Snacks", href: "/kategori/atistirmalik_J" },
        { text: "Water, Beverages", href: "/kategori/su-icecek_L" },
        { text: "Frozen, Ready Meals", href: "/kategori/donuk-hazir-gida_6" },
        { text: "Bakery, Pastry", href: "/kategori/firin-pastane_C" },
        {
          text: "Cakes and Ingredients",
          href: "/kategori/pasta-ve-malzemeleri_K",
        },
        { text: "Desserts, Ice Cream", href: "/kategori/tatli-dondurma_V" },
        { text: "Tea, Coffee", href: "/kategori/cay-kahve_8" },
        { text: "Cleaning, Detergents", href: "/kategori/temizlik-deterjan_N" },
        { text: "Personal Care", href: "/kategori/kisisel-bakim_P" },
        { text: "For Pets", href: "/kategori/evcil-dostlara_U" },
        { text: "Baby", href: "/kategori/bebek_R" },
        { text: "Home Life", href: "/kategori/ev-yasam_O" },
        {
          text: "Vitamins, Dermocosmetics",
          href: "/kategori/vitamin-dermokozmetik_2",
        },
        {
          text: "Technology, Digital Products",
          href: "/kategori/teknologi-digital-produkter_Y",
        },
        { text: "Books, Magazines", href: "/kategori/bøger-blade_S" },
      ],
      rights: "© 2024 All Rights Reserved",
    },
    features: [
      {
        title: "Thousands of product varieties",
        description: "You can easily access grocery shopping and much more!",
      },
      {
        title: "Cold chain delivery without breaking",
        description:
          "Your products are stored in cold storage and delivered to your door with refrigerated vehicles",
      },
      {
        title: "Always fresh",
        description:
          "No need to go to the market, all products come fresh to your door.",
      },
    ],
    categories: [
      "Fruits, Vegetables",
      "Meat, Chicken, Fish",
      "Dairy Products",
      "Breakfast",
      "Staple Foods",
      "Snacks",
      "Water, Beverages",
      "Frozen, Ready Meals",
      "Bakery, Pastry",
      "Cakes and Ingredients",
      "Desserts, Ice Cream",
      "Tea, Coffee",
      "Cleaning, Detergents",
      "Personal Care",
      "For Pets",
      "Baby",
      "Home Life",
      "Vitamins, Dermocosmetics",
      "Technology, Digital Products",
      "Books, Magazines",
    ],
    special: [
      "Red Discounts",
      "New Products",
      "Organic",
      "Household Economy",
      "Best Sellers",
      "We Carry",
      "Gluten-Free",
      "Vegan",
      "Ending Soon",
    ],
    chance: {
      title: "Deals",
      points: "Points",
      button: "Add to Cart",
      viewAll: "View All Products",
    },
    about: {
      title: "About Us",
      description1: "Shopping Call is designed as the first fully online supermarket. We develop technologies to offer mobile solutions to eliminate the difficulties of grocery shopping and provide next-generation solutions for your modern life. We aim to bring grocery products to our users within minutes. Over time, we plan to expand our original business model and offer different services to meet the various needs of our users.",
      description2: "If you want everything from fresh vegetables and fruits stored in cold storage to freshly baked bakery products from your favorite brands to come to your door; you can order through our platform.",
      founders: "Our Founders",
      serdar: {
        name: "Bahar ATASAYAR",
        bio: "With my extensive experience inoperations as a Public Relations Specialist, I will serve as CEO,leading the strategic direction of the company and overseeing business development. My background enables us to deeply understand the challenges our clients face and ensure thatour solution meets their needs. I will own 50% of thecompany.",
        contact: "Contact",
        address: "Address",
      },
      ali: {
        name: "Aycan OSLU",
        bio: " With expertise in administrative coordination and operational efficiency, Aycan will take on the role of COO, responsible for day‐to‐day operations and ensuring our business processes are streamlined and effective. Aycan's role is crucial to maintain operational excellence while scaling. He will own 25% of the company.",
        contact: "Contact",
        address: "Address",
      },
      burak: {
        name: "Büşra APAYDIN",
        bio: "A young entrepreneur with a new vision in marketing As a graduate of the marketing department of the university, Büşra will be responsible for today's new marketing techniques. Büşra's role is to increase the market share. She will own 15% of the company",
        contact: "Contact",
        address: "Address",
      },
      emir: {
        name: "Emircan YÜKSEL",
        bio: "As a Computer Programmer, Emircan is responsible for the design of the platform and system security. He will take part in updating the platform in the development of the market by making the necessary changes according to the needs of the application. He will own 10% of the company's shares",
        contact: "Contact",
        address: "Address",
      }
    },
    contact: {
      contactInfo: "Contact Information",
      phone: "Phone Number",
      serdar: "Bahar ATASAYAR - Founder",
      
      email: "Email Address",
      headquarters: "ShoppingCall Headquarters",
      corporateContact: "Corporate Contact Form",
      formDescription: "For corporate partnership requests and all questions, you can reach us by filling out the form below. If you want to become a supplier or need to contact our purchasing department regarding invoice payment processes, you must fill out this form first.",
      companyName: "Company Name",
      fullName: "Full Name",
      phoneNumber: "Phone Number",
      emailAddress: "Email Address",
      productGroup: "Product Group to be Sold",
      description: "Description",
      submit: "Submit",
    },
  },
  da: {
    header: {
      menu: "Menu",
      changeLanguage: "Skift Sprog",
      searchPlaceholder: "Søg blandt tusindvis af produkter",
      login: "Log ind eller Tilmeld dig",
      campaigns: "Kampagner",
      cart: "Kurv",
    },
    delivery: {
      title: "Hvor skal vi levere din ordre?",
      description:
        "Produktudvalg kan variere efter leveringsområde, vælg venligst dit område.",
      button: "Vælg Leveringsområde",
    },
    footer: {
      logoAlt: "İsteGelsin",
      social: {
        instagram: "Instagram",
        facebook: "Facebook",
        twitter: "Twitter",
        youtube: "YouTube",
      },
      corporate: "Corporate",
      corporateLinks: [
        {
          text: "Information Society Services",
          href: "/bilgitoplumuhizmetleri",
        },
        { text: "About Us", href: "/hakkimizda" },
        { text: "Help", href: "/yardim" },
        { text: "Contact", href: "/iletisim" },
        {
          text: "KVKK Clarification Text",
          href: "https://static.istegelsin.com/privacy.html",
        },
        {
          text: "Membership and Terms of Use",
          href: "https://static.istegelsin.com/terms.html",
        },
        {
          text: "Cookie Clarification Text",
          href: "https://static.istegelsin.com/cookie_privacy.html",
        },
      ],
      conceptCategories: "Concept Categories",
      conceptCategoryLinks: [
        { text: "Discount on Every Product!", href: "/her-urunde-indirim" },
        { text: "Red Discounts", href: "/grup/kirmizi-indirimler" },
        { text: "New Products", href: "/grup/yeniurunler0804" },
        { text: "Organic", href: "/grup/organik0710" },
        { text: "Household Economy", href: "/grup/pl121021" },
        { text: "Best Sellers", href: "/grup/coksatanlar" },
        { text: "We Carry", href: "/grup/biztasyalim" },
        { text: "Gluten-Free", href: "/grup/glutensiz0710" },
        { text: "Vegan", href: "/grup/vegan0710" },
        { text: "Ending Soon", href: "/grup/bitiriyoruz" },
      ],
      categories: "Categories",
      categoryLinks: [
        { text: "Fruits, Vegetables", href: "/kategori/meyve-sebze_A" },
        { text: "Meat, Chicken, Fish", href: "/kategori/et-tavuk-balik_B" },
        { text: "Dairy Products", href: "/kategori/sut-urunleri_D" },
        { text: "Breakfast", href: "/kategori/kahvaltilik_F" },
        { text: "Staple Foods", href: "/kategori/temel-gida_G" },
        { text: "Snacks", href: "/kategori/atistirmalik_J" },
        { text: "Water, Beverages", href: "/kategori/su-icecek_L" },
        { text: "Frozen, Ready Meals", href: "/kategori/donuk-hazir-gida_6" },
        { text: "Bakery, Pastry", href: "/kategori/firin-pastane_C" },
        {
          text: "Cakes and Ingredients",
          href: "/kategori/pasta-ve-malzemeleri_K",
        },
        { text: "Desserts, Ice Cream", href: "/kategori/tatli-dondurma_V" },
        { text: "Tea, Coffee", href: "/kategori/cay-kahve_8" },
        { text: "Cleaning, Detergents", href: "/kategori/temizlik-deterjan_N" },
        { text: "Personal Care", href: "/kategori/kisisel-bakim_P" },
        { text: "For Pets", href: "/kategori/evcil-dostlara_U" },
        { text: "Baby", href: "/kategori/bebek_R" },
        { text: "Home Life", href: "/kategori/ev-yasam_O" },
        {
          text: "Vitamins, Dermocosmetics",
          href: "/kategori/vitamin-dermokozmetik_2",
        },
        {
          text: "Technology, Digital Products",
          href: "/kategori/teknologi-digital-produkter_Y",
        },
        { text: "Books, Magazines", href: "/kategori/bøger-blade_S" },
      ],
      rights: "© 2024 Alle Rettigheder Forbeholdes",
    },
    features: [
      {
        title: "Tusindvis af produktvarianter",
        description:
          "Du kan nemt få adgang til dagligvareindkøb og meget mere!",
      },
      {
        title: "Koldkæde levering uden brud",
        description:
          "Dine produkter opbevares i kolde lagre og leveres til din dør med køretøjer med køleskab",
      },
      {
        title: "Altid frisk",
        description:
          "Ingen grund til at gå til markedet, alle produkter kommer friske til din dør.",
      },
    ],
    categories: [
      "Frugter, Grøntsager",
      "Kød, Kylling, Fisk",
      "Mejeriprodukter",
      "Morgenmad",
      "Basisvarer",
      "Snacks",
      "Vand, Drikkevarer",
      "Frosne, Færdigretter",
      "Bageri, Konditori",
      "Kager og Ingredienser",
      "Desserter, Is",
      "Te, Kaffe",
      "Rengøring, Vaskemidler",
      "Personlig Pleje",
      "Til Kæledyr",
      "Baby",
      "Hjemmeliv",
      "Vitaminer, Dermokosmetik",
      "Teknologi, Digitale Produkter",
      "Bøger, Magasiner",
    ],
    special: [
      "Røde Rabatter",
      "Nye Produkter",
      "Økologisk",
      "Husholdningsøkonomi",
      "Bedst Sælgende",
      "Vi Bærer",
      "Glutenfri",
      "Veganer",
      "Slutter Snart",
    ],
    chance: {
      title: "Tilbud",
      points: "Point",
      button: "Tilføj til kurv",
      viewAll: "Se alle produkter",
    },
    about: {
      title: "Om os",
      description1: "Shopping Call er designet som det første fuldt online supermarked. Vi udvikler teknologier til at tilbyde mobile løsninger for at eliminere vanskelighederne ved dagligvareindkøb og levere næste generations løsninger til dit moderne liv. Vi sigter mod at bringe dagligvarer til vores brugere inden for få minutter. Over tid planlægger vi at udvide vores oprindelige forretningsmodel og tilbyde forskellige tjenester for at imødekomme vores brugeres forskellige behov.",
      description2: "Hvis du vil have alt fra friske grøntsager og frugter opbevaret i kold opbevaring til friskbagte bageriprodukter fra dine yndlingsmærker til din dør; du kan bestille gennem vores platform.",
      founders: "Vores stiftere",
      serdar: {
        name: "Serdar ATASAYAR",
        bio: "Uddannet fra Anadolu University, Department of Public Relations. Han har arbejdet som seniorleder i forskellige virksomheder i fødevareindustrien i over 20 år. Han fortsætter sit arbejde med et nyt forretningsidéprojekt. For nylig er han grundlæggeren af supermarkedets ordreplatform gennem Shopping Call-applikationen.",
        contact: "Kontakt",
        address: "Adresse",
      },
      ali: {
        name: "Ali AŞILI",
        bio: "Uddannet fra Anadolu University, Department of Labor Economics. Han har arbejdet som økonomichef i forskellige virksomheder i årevis. For nylig er han en ny venturepartner af supermarkedets ordreplatform gennem Shopping Call-applikationen.",
        contact: "Kontakt",
        address: "Adresse",
      },
      burak: {
        name: "Burak MADEN",
        bio: "Uddannet fra Atatürk University, Department of Computer Programming. Han er en ny venturepartner af supermarkedets ordreplatform gennem Shopping Call-applikationen.",
        contact: "Kontakt",
        address: "Adresse",
      }
    },
    contact: {
      contactInfo: "Kontaktinformation",
      phone: "Telefonnummer",
      serdar: "Serdar ATASAYAR - Grundlægger",
      ali: "Ali AŞILI - Partner",
      burak: "Burak MADEN - Partner",
      email: "E-mail Adresse",
      headquarters: "ShoppingCall Hovedkvarter",
      corporateContact: "Virksomheds Kontaktformular",
      formDescription: "For virksomhedspartnerskabsanmodninger og alle spørgsmål kan du kontakte os ved at udfylde formularen nedenfor. Hvis du ønsker at blive leverandør eller skal kontakte vores indkøbsafdeling vedrørende fakturabetalingsprocesser, skal du først udfylde denne formular.",
      companyName: "Firmanavn",
      fullName: "Fulde Navn",
      phoneNumber: "Telefonnummer",
      emailAddress: "E-mail Adresse",
      productGroup: "Produktgruppe til Salg",
      description: "Beskrivelse",
      submit: "Indsend",
    },
  },
};
export default texts;