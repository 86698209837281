import React, { useContext, useState } from 'react';
import './LanguageSelector.css';
import { LanguageContext } from '../contexts/LanguageContext';

const LanguageSelector = () => {
  const { language, setLanguage } = useContext(LanguageContext);
  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const selectLanguage = (lang) => {
    setLanguage(lang);
    setShowOptions(false);
  };

  return (
    <div className="language-selector">
      <button className="language-button" onClick={toggleOptions}>
        {language === 'tr' ? 'Dil Seçin' : language === 'da' ? 'Vælg sprog' : 'Select Language'}
      </button>
      {showOptions && (
        <div className="language-options">
          <button onClick={() => selectLanguage('tr')}>Türkçe</button>
          <button onClick={() => selectLanguage('en')}>English</button>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
