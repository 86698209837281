// components/RedPage.js

import React, { useState, useEffect } from 'react';
import './RedPage.css';
import productsData from '../data/productsData.json';

const RedPage = () => {
  const [products, setProducts] = useState(productsData);
  const [filteredProducts, setFilteredProducts] = useState(productsData);
  const [cart, setCart] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState(['all']);
  const [selectAll, setSelectAll] = useState(true);

  useEffect(() => {
    filterProducts();
  }, [checkedCategories]);

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    if (value === 'all') {
      setCheckedCategories(checked ? ['all'] : []);
      setSelectAll(checked);
    } else {
      setCheckedCategories((prev) => {
        if (checked) {
          return [...prev.filter((cat) => cat !== 'all'), value];
        } else {
          const newCategories = prev.filter((cat) => cat !== value);
          return newCategories.length ? newCategories : ['all'];
        }
      });
      setSelectAll(false);
    }
  };

  const filterProducts = () => {
    setFilteredProducts(
      products.filter(
        (product) =>
          checkedCategories.includes('all') ||
          checkedCategories.includes(product.category)
      )
    );
  };

  const handleSort = (sortBy) => {
    const sortedProducts = [...filteredProducts].sort((a, b) => {
      switch (sortBy) {
        case 'Price Ascending':
          return a.price - b.price;
        case 'Price Descending':
          return b.price - a.price;
        case 'Alphabetical (A to Z)':
          return a.name.localeCompare(b.name);
        default:
          return 0;
      }
    });
    setFilteredProducts(sortedProducts);
  };

  const addToCart = (product) => {
    setCart([...cart, product]);
  };

  const removeFromCart = (index) => {
    setCart(cart.filter((_, i) => i !== index));
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <>
      <header className="header">
        <div className="header-left">
          <img src="../assets/menu-icon.svg" alt="Menu" className="menu-icon" />
          <select className="language-selector">
            <option value="tr">Türkçe</option>
            <option value="en">English</option>
          </select>
          <img src="../assets/ig-logo.svg" alt="İsteGelsin" className="logo" />
        </div>
        <div className="header-center">
          <img src="../assets/search-input-icon.svg" alt="Search" className="search-icon" />
          <input type="text" placeholder="Search..." className="search-input" />
        </div>
        <div className="header-right">
          <img src="../assets/kampanya-icon.svg" alt="Campaigns" className="kampanya-icon" />
          <button className="login-button">Login</button>
          <img src="../assets/cart-icon.svg" alt="My Cart" className="cart-icon" />
        </div>
      </header>
      <main>
        <div className="dropdown">
          <button className="dropdown-button">Recommended Sorting</button>
          <div className="dropdown-content">
            <a href="#" onClick={() => handleSort('Recommended Sorting')}>Recommended Sorting</a>
            <a href="#" onClick={() => handleSort('Price Ascending')}>Price Ascending</a>
            <a href="#" onClick={() => handleSort('Price Descending')}>Price Descending</a>
            <a href="#" onClick={() => handleSort('Alphabetical (A to Z)')}>Alphabetical (A to Z)</a>
          </div>
        </div>
        <div className="container">
          <div className="sidebar1">
            <h2>Categories</h2>
            <div className="related-categories">
              <form id="filter-form">
                <label>
                  <input type="checkbox" id="category1" name="category" value="all" checked={selectAll} onChange={handleCategoryChange} />
                  <b>Select All</b>
                </label>
                <br />
                <label>
                  <input type="checkbox" name="category" value="meyveSebze" checked={checkedCategories.includes('meyveSebze')} onChange={handleCategoryChange} />
                  Fruit, Vegetable
                </label>
                <br />
                <label>
                  <input type="checkbox" name="category" value="etTavukBalik" checked={checkedCategories.includes('etTavukBalik')} onChange={handleCategoryChange} />
                  Meat, Chicken, Fish
                </label>
                <br />
                <label>
                  <input type="checkbox" name="category" value="sutUrunleri" checked={checkedCategories.includes('sutUrunleri')} onChange={handleCategoryChange} />
                  Dairy Products
                </label>
                <br />
                <label>
                  <input type="checkbox" name="category" value="kahvaltilik" checked={checkedCategories.includes('kahvaltilik')} onChange={handleCategoryChange} />
                  Breakfast
                </label>
                <br />
                <label>
                  <input type="checkbox" name="category" value="temelGida" checked={checkedCategories.includes('temelGida')} onChange={handleCategoryChange} />
                  Basic Food
                </label>
                <br />
                <label>
                  <input type="checkbox" name="category" value="atistirmalik" checked={checkedCategories.includes('atistirmalik')} onChange={handleCategoryChange} />
                  Snacks
                </label>
                <br />
                <label>
                  <input type="checkbox" name="category" value="suIcecek" checked={checkedCategories.includes('suIcecek')} onChange={handleCategoryChange} />
                  Water, Beverage
                </label>
                <br />
                <label>
                  <input type="checkbox" name="category" value="firinPastane" checked={checkedCategories.includes('firinPastane')} onChange={handleCategoryChange} />
                  Bakery, Patisserie
                </label>
                <br />
                <label>
                  <input type="checkbox" name="category" value="tatliDondurma" checked={checkedCategories.includes('tatliDondurma')} onChange={handleCategoryChange} />
                  Dessert, Ice Cream
                </label>
                <br />
                <label>
                  <input type="checkbox" name="category" value="temizlikDeterjan" checked={checkedCategories.includes('temizlikDeterjan')} onChange={handleCategoryChange} />
                  Cleaning, Detergent
                </label>
                <br />
                <label>
                  <input type="checkbox" name="category" value="kisiselBakim" checked={checkedCategories.includes('kisiselBakim')} onChange={handleCategoryChange} />
                  Personal Care
                </label>
                <br />
                <label>
                  <input type="checkbox" name="category" value="vitaminDermokozmetik" checked={checkedCategories.includes('vitaminDermokozmetik')} onChange={handleCategoryChange} />
                  Vitamin, Dermocosmetic
                </label>
                <br />
              </form>
            </div>
          </div>
          <div className="main-content">
            <div className="product-container" id="product-container">
              {filteredProducts.map((product) => (
                <div className="product-card" key={product.id} data-category={product.category} data-price={product.price}>
                  <img src={product.imgSrc} alt={product.name} />
                  <h3>{product.name}</h3>
                  <p>{product.price}₺</p>
                  <button type="button" className="satinal" onClick={() => addToCart(product)}>Buy</button>
                </div>
              ))}
            </div>
          </div>
          <div className="cart-dropdown">
            <button className="cart-button">Cart</button>
            <div className="cart-content">
              <h2>Cart</h2>
              <div id="cart-container">
                {cart.length === 0 ? (
                  <p>Your cart is empty.</p>
                ) : (
                  <ul>
                    {cart.map((item, index) => (
                      <li key={index}>
                        {item.name} - {item.price}₺
                        <button onClick={() => removeFromCart(index)}>Remove</button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <button id="clear-cart" onClick={clearCart}>Clear Cart</button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default RedPage;
