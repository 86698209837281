import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import "./DeliverySection.css";

function DeliverySection() {
  const { texts } = useContext(LanguageContext);

  return (
    <div className="delivery-section">
      <h1>{texts.delivery.title}</h1>
      <p>{texts.delivery.description}</p>
      <button>{texts.delivery.button}</button>
    </div>
  );
}

export default DeliverySection;
