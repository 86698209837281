import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import features from "../data/features.json";
import "./Features.css";

function Features() {
  const { texts } = useContext(LanguageContext);

  return (
    <section className="features">
      {features.map((feature, index) => (
        <div key={feature.id} className="feature">
          <img
            src={feature.image}
            alt={texts.features[index].title}
            className="feature-image"
          />
          <h3>{texts.features[index].title}</h3>
          <p>{texts.features[index].description}</p>
        </div>
      ))}
    </section>
  );
}

export default Features;
