import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Banner from "./components/Banner";
import Categories from "./components/Categories";
import Features from "./components/Features";
import DeliverySection from "./components/DeliverySection";
import Footer from "./components/Footer";
import Chance from "./components/Chance";
import RedPage from "./components/RedPage";
import About from "./components/About";
import Contact from "./components/Contact";
import { LanguageProvider } from "./contexts/LanguageContext";
import "./App.css";

function MainLayout() {
  return (
    <>
      <Banner />
      <DeliverySection />
      <Categories />
      <Chance />
      <Features />
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <LanguageProvider>
        <Header />
        <div className="App">
          <Routes>
            <Route path="/" element={<MainLayout />} />
            <Route path="/redpage" element={<RedPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </LanguageProvider>
    </Router>
  );
}

export default App;
