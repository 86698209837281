import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import chance from "../data/chance.json";
import "./Chance.css";

function Chance() {
  const { texts } = useContext(LanguageContext);

  return (
    <section className="chance">
      <h2>{texts.chance.title}</h2>
      <div className="chance-container">
        {chance.map((item) => (
          <div key={item.id} className="chance-item">
            <img src={item.image} alt={item.name} className="chance-image" />
            <div className="chance-info">
              <p className="chance-old-price">{item.oldPrice}</p>
              <p className="chance-new-price">{item.newPrice}</p>
              <p className="chance-name">{item.name}</p>
              <p className="chance-points">
                {item.points} {texts.chance.points}
              </p>
            </div>
            <button className="chance-button">{texts.chance.button}</button>
          </div>
        ))}
      </div>
      <button className="view-all-button">{texts.chance.viewAll}</button>
    </section>
  );
}

export default Chance;
