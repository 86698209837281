import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import "./About.css";
import atasayar from "../assets/atasayar.jpeg";
import aycan from "../assets/aycan.jpeg";
import busra from "../assets/busra.jpeg";
import emir from "../assets/emir.jpeg";

function About() {
  const { texts } = useContext(LanguageContext);

  return (
    <div className="about-container">
      <h2>{texts.about.title}</h2>
      <p>{texts.about.description1}</p>
      <p>{texts.about.description2}</p>
      <hr />
      <h2>{texts.about.founders}</h2>
      <div className="kurucu">
        <img src={atasayar} alt="Bahar ATASAYAR" />
        <div>
          <h3>{texts.about.serdar.name}</h3>
          <p>{texts.about.serdar.bio}</p>
          <p><strong>{texts.about.serdar.contact}:</strong> +90 543 434 6744 <p><strong>Mail: </strong>baharatasayar@gmail.com</p></p>
         

          <p><strong>{texts.about.serdar.address}:</strong> Atakent Mah. 2128. Sokak 15/16 Etimesgut/ANKARA</p>
        </div>
      </div>
      <div className="kurucu">
        <img src={aycan} alt="Aycan Oslu" />
        <div>
          <h3>{texts.about.ali.name}</h3>
          <p>{texts.about.ali.bio}</p>
          <p><strong>{texts.about.ali.contact}:</strong> aycan.062024@gmail.com</p>
        </div>
      </div>
      <div className="kurucu">
        <img src={busra} alt="Buşra Apaydin" />
        <div>
          <h3>{texts.about.burak.name}</h3>
          <p>{texts.about.burak.bio}</p>
          <p><strong>{texts.about.burak.contact}:</strong> bapaydin445@gmail.com </p>
        </div>
      </div>
      <div className="kurucu">
        <img src={emir} alt="EmircanYuksel" />
        <div>
          <h3>{texts.about.emir.name}</h3>
          <p>{texts.about.emir.bio}</p>
          <p><strong>{texts.about.emir.contact}:</strong> emircanyuksel24@gmail.com</p>
        </div>
      </div>
    </div>
  );
}

export default About;
