import React, { useContext, useState } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import { Link } from "react-router-dom";
import logo from "../assets/ig-logo.svg";
import menuIcon from "../assets/menu-icon.svg";
import searchIcon from "../assets/search-input-icon.svg";
import kampanyaIcon from "../assets/kampanya-icon.svg";
import cartIcon from "../assets/cart-icon.svg";
import LanguageSelector from "./LanguageSelector";
import "./Header.css";

function Header() {
  const { texts } = useContext(LanguageContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="header-left">
        <img
          src={menuIcon}
          alt={texts.header.menu}
          className="menu-icon"
          onClick={toggleMenu}
        />
        <LanguageSelector />
      </div>
      <div className="header-center">
        <img src={searchIcon} alt="Search" className="search-icon" />
        <input
          type="text"
          placeholder={texts.header.searchPlaceholder}
          className="search-input"
        />
      </div>
      <div className="header-right">
        <img
          src={kampanyaIcon}
          alt={texts.header.campaigns}
          className="kampanya-icon"
        />
        <button className="login-button">{texts.header.login}</button>
        <img src={cartIcon} alt={texts.header.cart} className="cart-icon" />
      </div>
      <div className={`sidebar ${menuOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <span>ShoppingCall</span>
          <button className="close-button" onClick={toggleMenu}>X</button>
        </div>
        <Link to="/about" className="menu-item" onClick={toggleMenu}>
          About Us
        </Link>
        <Link to="/contact" className="menu-item" onClick={toggleMenu}>
          Contact
        </Link>
      </div>
      {menuOpen && <div className="backdrop" onClick={toggleMenu}></div>}
    </header>
  );
}

export default Header;
