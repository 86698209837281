import React, { useContext } from "react";
import "./Contact.css";
import { LanguageContext } from "../contexts/LanguageContext";

function Contact() {
  const { texts } = useContext(LanguageContext);

  return (
    <div className="contact-container">
      <div className="bilgiler-form-container">
        <div className="iletisim-bilgilerimiz">
          <h2>{texts.contact.contactInfo}</h2>
          <p><strong>{texts.contact.phone}</strong></p>
          <p>{texts.contact.serdar}</p>
          <p>+90 543 434 6744</p>
          
          <p><strong>{texts.contact.email}</strong></p>
          <p>info@shoppingcall.com</p>
          <p><strong>{texts.contact.headquarters}</strong></p>
          <p>Atakent Mah. 2128. Sokak 15/16 Etimesgut/ANKARA</p>
          <div className="map-container">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3059.177533733645!2d32.65102007640951!3d39.93741838467183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d339c31ba788c1%3A0xad992e13fde5ffd6!2sAlsancak%2C%202128.%20Sk.%20No%3A15%20D%3A16%2C%2006790%20Etimesgut%2FAnkara!5e0!3m2!1str!2str!4v1717944070188!5m2!1str!2str" width="600" height="400" style={{ border: 0 }} allowFullScreen="" loading="eager" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
        <div className="kurumsal-iletisim-formu">
          <h2>{texts.contact.corporateContact}</h2>
          <p>{texts.contact.formDescription}</p>
          <form>
            <div className="form-group">
              <label htmlFor="firmaAdi">{texts.contact.companyName} *</label>
              <input type="text" id="firmaAdi" name="firmaAdi" required />
            </div>
            <div className="form-group">
              <label htmlFor="adSoyad">{texts.contact.fullName} *</label>
              <input type="text" id="adSoyad" name="adSoyad" required />
            </div>
            <div className="form-group">
              <label htmlFor="telefon">{texts.contact.phoneNumber} *</label>
              <input type="tel" id="telefon" name="telefon" required />
            </div>
            <div className="form-group">
              <label htmlFor="eposta">{texts.contact.emailAddress} *</label>
              <input type="email" id="eposta" name="eposta" required />
            </div>
            <div className="form-group">
              <label htmlFor="urunGrubu">{texts.contact.productGroup} *</label>
              <input type="text" id="urunGrubu" name="urunGrubu" required />
            </div>
            <div className="form-group">
              <label htmlFor="aciklama">{texts.contact.description} *</label>
              <textarea id="aciklama" name="aciklama" required></textarea>
            </div>
            <button type="submit">{texts.contact.submit}</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
