import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../contexts/LanguageContext";
import footer from "../data/footer.json";
import "./Footer.css";

function Footer() {
  const { texts } = useContext(LanguageContext);

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-social">
          <a href="https://www.instagram.com/istegelsin/">
            <img
              src={footer.social.instagram}
              alt="Instagram"
            />
          </a>
          <a href="https://www.facebook.com/istegelsintr/">
            <img
              src={footer.social.facebook}
              alt="Facebook"
            />
          </a>
          <a href="https://twitter.com/istegelsin_tr">
            <img
              src={footer.social.twitter}
              alt="Twitter"
            />
          </a>
          <a href="https://www.youtube.com/channel/UCdkrqqTNLai_qdncompe4gw">
            <img
              src={footer.social.youtube}
              alt="YouTube"
            />
          </a>
        </div>
      </div>
      <div className="footer-middle">
        <div className="footer-column">
          <h3>{texts.footer.corporate}</h3>
          <ul>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>{texts.footer.conceptCategories}</h3>
          <ul>
            {texts.footer.conceptCategoryLinks.map((link, index) => (
              <li key={index}>
                <a href={link.href}>{link.text}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer-column">
          <h3>{texts.footer.categories}</h3>
          <ul>
            {texts.footer.categoryLinks.map((link, index) => (
              <li key={index}>
                <a href={link.href}>{link.text}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-payment">
          {footer.payment.map((image, index) => (
            <img key={index} src={image} alt={`Payment ${index + 1}`} />
          ))}
        </div>
        <div className="footer-copyright">
          {texts.footer.rights}
        </div>
        <div className="footer-cards">
          {footer.cards.map((image, index) => (
            <img key={index} src={image} alt={`Card ${index + 1}`} />
          ))}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
